<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name (En)"
            rules="required"
          >
            <b-form-group
              label="Name (En)"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="donationForm.name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name (Ar)"
            rules="required"
          >
            <b-form-group
              label="Name (Ar)"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="donationForm.name_ar"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Service Code"
            rules="required"
          >
            <b-form-group
              label="Service Code"
              label-for="service_code"
            >
              <b-form-input
                id="service_code"
                v-model="donationForm.service_code"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Service Code"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Service Provider Name"
            rules="required"
          >
            <b-form-group
              label="Service Provider Name"
              label-for="service_provider_name"
            >
              <b-form-input
                id="service_provider_name"
                v-model="donationForm.service_provider_name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Service Provider Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Service Provider Code"
            rules="required"
          >
            <b-form-group
              label="Service Provider Code"
              label-for="service_provider_code"
            >
              <b-form-input
                id="service_provider_code"
                v-model="donationForm.service_provider_code"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Service Provider Code"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Short Code"
            rules="required"
          >
            <b-form-group
              label="Short Code"
              label-for="short_code"
            >
              <b-form-input
                id="short_code"
                v-model="donationForm.short_code"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Short Code"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <b-media
            no-body
            class="mb-1"
          >
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  v-img
                  rounded
                  :src="donationForm.image"
                  height="100"
                  width="100"
                />
              </b-link>
              <!--/ image -->
            </b-media-aside>

            <b-media-body>
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="selectImg()"
              >
                Upload Image
              </b-button>
              <b-form-file
                ref="refImg"
                accept=".jpg, .png, .jpeg"
                :hidden="true"
                plain
                @change="changeImage($event)"
              />
              <!--/ upload button -->
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          v-if="errorMsg"
          cols="12"
        >
          <p class="text-danger">
            {{ errorMsg }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addDonation()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addDonation()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>

      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

export default {
  components: {
  },
  data() {
    return {
      required,
      id: this.$store.state.generalIds.id,
      selected: [],
      allSelected: [],
      indeterminate: false,
      categories: [],
      countryId: '',
      loader: false,
      errorMsg: '',
      errors: {},
      types: [
        {
          name: 'Water',
        },
        {
          name: 'Gas',
        },
        {
          name: 'Electricity Bills',
        },
      ],
      serviceTransactionTypes: [
        {
          name: 'Payment',
        },
        {
          name: 'inquiry',
        },

      ],

    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const donationForm = ref({
      image: null,

    })
    // eslint-disable-next-line camelcase
    const image = ref('')
    return {
      getValidationState,
      donationForm,
      image,
    }
  },
  mounted() {
    this.showDonation()
  },
  methods: {
    selectImg() {
      this.$refs.refImg.$el.click()
    },

    changeImage(e) {
      // eslint-disable-next-line prefer-destructuring
      this.image = e.target.files[0]
      const input = e.target
      const img = new Image()
      img.src = window.URL.createObjectURL(e.target.files[0])
      img.onload = () => {
        if (input.files) {
          const reader = new FileReader()
          reader.onload = er => {
            this.donationForm.image = er.target.result
          }
          // eslint-disable-next-line prefer-destructuring
          this.image = input.files[0]
          reader.readAsDataURL(input.files[0])
        }
      }
    },
    showDonation() {
      if (this.$route.params.id) {
        axios.get(`donations/${this.$route.params.id}`).then(res => {
          this.donationForm = res.data.data
        })
      } else {
        return false
      }
      return true
    },

    addDonation() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.donationForm) {
          if (key !== 'image') {
            formData.append(key, this.donationForm[key])
          }
        }
        formData.delete('image')
        if (this.image) {
          formData.append('image', this.image)
        }
        formData.append('_method', 'PUT')

        axios.post(`donations/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'donations' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                name: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 400) {
            this.errorMsg = error.response.data.error
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        const formData = new FormData()

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.donationForm) {
          if (key !== 'image') {
            formData.append(key, this.donationForm[key])
          }
        }
        formData.delete('image')
        if (this.image) {
          formData.append('image', this.image)
        }
        this.loader = true
        axios.post('donations', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'donations' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                name: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 400) {
            this.errorMsg = error.response.data.error
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },

  },

}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  </style>
